<template>
  <div @mouseenter="store.getComponentCursor($event, null)" class="media-single ">
    <div class="mobile-header">
      <svg width="25" height="25"
           viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.81034 10.5L24.252 10.5L24.252 13.5L6.58885
               13.5L15.1838 22.0949L13.0625 24.2163L2.01762
                13.1714L0.956957 12.1108L2.01762 11.0501L13.0625
                 0.00524362L15.1838 2.12656L6.81034 10.5Z"
              fill="black"/>
      </svg>
      <div class="share-container">
        <a
            :href="`${socialMedia.url}${getFullUrl}`"
            onclick="window.open(this.href,'targetWindow',
                                   `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=800`);
 return false;"
        >
          <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M29.5725 27.0443C29.5761 28.5999 28.8848 30.076 27.687 31.0686C26.4893 32.0615 24.9106 32.4671 23.3828 32.1746C21.8547 31.8823 20.5375 30.9226 19.7908 29.5578C19.0442 28.193 18.9466 26.566 19.5243 25.1215L9.56299 19.3723C8.66273 20.5116 7.3278 21.2236 5.88013 21.3365C4.43247 21.4493 3.00343 20.9529 1.93759 19.9667C0.871518 18.9806 0.265625 17.5944 0.265625 16.1424C0.265625 14.6904 0.871577 13.3043 1.93759 12.3181C3.00343 11.332 4.43247 10.8356 5.88013 10.9483C7.3278 11.0613 8.66273 11.7733 9.56299 12.9126L19.5237 7.16346C18.8645 5.51353 19.0893 3.64154 20.12 2.19411C21.1507 0.746677 22.8463 -0.0775801 24.6212 0.00576848C26.3962 0.089117 28.0071 1.06861 28.8978 2.60627C29.7884 4.14369 29.8367 6.02855 29.0259 7.60959C28.2152 9.19063 26.6564 10.2514 24.8882 10.4255C23.1197 10.5997 21.3842 9.86315 20.2807 8.47058L10.32 14.2197C10.815 15.4541 10.815 16.8315 10.32 18.0658L20.2807 23.8142C21.1803 22.6752 22.5147 21.9634 23.9622 21.8507C25.4094 21.7377 26.838 22.2343 27.9031 23.2205C28.9685 24.2066 29.5737 25.5928 29.5728 27.0444L29.5725 27.0443Z"
                  fill="black"/>
          </svg>
        </a>
      </div>
    </div>
    <span class="date fira"> {{ $fn.tr('PRESS RELEASE') }} {{ item.date }}</span>
    <h2 class="header-title fira">{{ item.title }}</h2>
    <div class="main-media-container">
      <div class="trim top"></div>
      <div @click="goBack" class="trim back top">
        <svg width="52" height="54" viewBox="0 0 52 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.3291 24.5L51.5 24.5L51.5 29.5L9.83251 29.5L30.5687 50.2362L27.0332 53.7717L2.2775 29.016L0.509729 27.2483L2.2775 25.4805L27.0332 0.724815L30.5687 4.26035L10.3291 24.5Z"
                fill="black"/>
        </svg>
      </div>
      <div class="trim  right"></div>
      <div class="trim  bottom"></div>
      <div class="trim  bottom second"></div>
      <div class="image-inner">
        <picture class="project-item-image">
          <source
              media="(max-width:767px)"
              :srcset="item.image.desktop"
          />
          <source
              media="(max-width:1023px)"
              :srcset="item.image.tablet"
          />
          <img class="image"
               :src="item.image.mobile"
          />
        </picture>
      </div>
      <div class="share-container">
        <a
            :href="`${socialMedia.url}${getFullUrl}`"
            onclick="window.open(this.href,'targetWindow',
                                   `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=800`);
 return false;"
        >
          <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M29.5725 27.0443C29.5761 28.5999 28.8848 30.076 27.687 31.0686C26.4893 32.0615 24.9106 32.4671 23.3828 32.1746C21.8547 31.8823 20.5375 30.9226 19.7908 29.5578C19.0442 28.193 18.9466 26.566 19.5243 25.1215L9.56299 19.3723C8.66273 20.5116 7.3278 21.2236 5.88013 21.3365C4.43247 21.4493 3.00343 20.9529 1.93759 19.9667C0.871518 18.9806 0.265625 17.5944 0.265625 16.1424C0.265625 14.6904 0.871577 13.3043 1.93759 12.3181C3.00343 11.332 4.43247 10.8356 5.88013 10.9483C7.3278 11.0613 8.66273 11.7733 9.56299 12.9126L19.5237 7.16346C18.8645 5.51353 19.0893 3.64154 20.12 2.19411C21.1507 0.746677 22.8463 -0.0775801 24.6212 0.00576848C26.3962 0.089117 28.0071 1.06861 28.8978 2.60627C29.7884 4.14369 29.8367 6.02855 29.0259 7.60959C28.2152 9.19063 26.6564 10.2514 24.8882 10.4255C23.1197 10.5997 21.3842 9.86315 20.2807 8.47058L10.32 14.2197C10.815 15.4541 10.815 16.8315 10.32 18.0658L20.2807 23.8142C21.1803 22.6752 22.5147 21.9634 23.9622 21.8507C25.4094 21.7377 26.838 22.2343 27.9031 23.2205C28.9685 24.2066 29.5737 25.5928 29.5728 27.0444L29.5725 27.0443Z"
                  fill="black"/>
          </svg>
        </a>
      </div>
    </div>
    <div class="content" v-html="item.firsttext"></div>
    <div v-if="item.slides.length" class="slider-container">
      <Swiper
          :slides-per-view="swiperOptions?.slidesPerView"
          :space-between="swiperOptions?.spaceBetween"
          :breakpoints="swiperOptions?.breakpoints"
          :speed="800"
          :free-mode="true"
          :navigation="{
            nextEl: `.infrastructure .swiper-button-next${item.id}`,
            prevEl: `.infrastructure .swiper-button-prev${item.id}`,
          }"
      >
        <SwiperSlide v-for="(image, index) in item.slides"
                     @mouseenter="store.getComponentCursor($event, 'scale')"
                     @mouseleave="store.getComponentCursor($event, null)"
                     :key="index">
          <img :src="image.url"
               @click="showGallery( item.slides)"
               alt="">
          <span>{{ image.header }}</span>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="content" v-html="item.secondtext"></div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import moment from 'moment'
import {useRoute, useRouter} from "vue-router";
import {defineProps} from "vue";
import {ref} from "vue";
import {handleResize} from "@/composables/resizeHandler";
import {useMenuStore} from "@/store/pinia/menu";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {useGallery} from "@/store/pinia/gallery";

const props = defineProps({
  contentData: {
    type: Object,
  },
});
let galleryStore = useGallery()
let store = useMenuStore()
const {windowWidth, windowHeight} = handleResize();
let route = useRoute()
const isVideoOpen = ref(false);
const videoCloseTO = ref(null);
const activeVideo = ref(null);
const socialMedia = computed(() => store.indx?.widgets?.share_links?.facebook || {})
const getFullUrl = computed(() => {
  return window.location.href;
});

const swiperOptions = {
  slidesPerView: 1.6,
  spaceBetween: 10,
  speed: 800,
  breakpoints: {
    767: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
};


let item = computed(() => {
  let item = props.contentData?.data?.list?.[0]
  let dateFormat = 'YYYY.MM.DD'
  return {
    ...item,
    date: store.getLang === 'ge' ? moment(item.date).locale("ka").format(dateFormat)
        : store.getLang === 'ru' ? moment(item.date).locale("ru").format(dateFormat) : moment(item.date).format(dateFormat),
    image: item.innerCover?.[0]?.devices || {},
    slides: item.sliderimages?.map(image => ({
      ...image,
      url: image?.devices?.desktop,
      header: image.title?.title
    }))
  }
})
let router = useRouter()
const goBack = () => {
  let path = route?.path?.split('/')
  path?.splice(-1)

  router.push({path: path?.join('/')})

}
const changeUrl = () => {
  router.push({
    path: '/media',
  })
}
const showGallery = (items) => {
  galleryStore.setGalleryItems(items)
}

</script>

<style lang="scss" scoped>
.media-single {
  color: $primaryNavy;
  @media only screen and (max-width: 1650px) and (min-width: 1024px) {
  }
  @media only screen and (max-width: 1023px) {
  }
  @media only screen and (max-width: 767px) {
  }

  .date {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    transition: all .45s ease-out;
    display: block;
    margin: 0 auto;
    text-align: center;
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 10px;
    }
  }

  .mobile-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    @media only screen and (max-width: 767px) {
      display: flex;
    }
    .share-container {
      display: flex !important;
    }
  }

  .header-title {
    font-weight: 400;
    font-size: 55px;
    line-height: 89px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    transition: all .45s ease-out;
    max-width: 900px;
    margin: 0 auto;
    width: fit-content;
    margin-top: 15px;
    @media only screen and (max-width: 1440px) {
      font-size: 34px;
      line-height: 38px;
      margin-top: 0;
    }
  }
x
  .slider-container:deep {
    margin-top: 55px;
    padding-left: 120px;
    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
      padding-left: 100px;
    }
    @media only screen and (max-width: 767px) {
      padding-left: 16px;
      margin-top: 34px;
    }

    .swiper-wrapper {


      .swiper-slide {
        img {
          height: 560px;
          width: 100%;
          object-fit: cover;
          @media only screen and (max-width: 1650px) and (min-width: 1281px) {
            height: 450px;
          }
          @media only screen and (max-width: 1280px) and (min-width: 1024px) {
            height: 373px;
          }
          @media only screen and (max-width: 1023px) {
            height: 224px;
          }
          @media only screen and (max-width: 767px) {
            height: 200px;
          }
        }
      }
    }
  }

  .image {
    width: 100%;
    height: 100vh;
    object-fit: cover;

    @media only screen and (max-width: 767px) {
      height: 530px;
    }
  }

  .main-media-container {
    position: relative;

    .trim {
      position: absolute;
      background: white;
      z-index: 2;

      &.top {
        top: -2px;
        left: 0;
        width: 250px;
        height: 50px;
        @media only screen and (max-width: 767px) {
          height: 12px;
          width: 200px;
        }

        &.back {
          top: 47px;
          width: 120px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
      }

      &.right {
        right: 0;
        top: 0;
        height: 70px;
        width: 15px;
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }

      &.bottom {
        bottom: 26px;
        left: 0;
        width: 150px;
        height: 20px;
        @media only screen and (max-width: 767px) {
          right: 0;
          height: 10px;
          width: 200px;
          left: unset;
          bottom: 0;
        }

        &.second {
          width: 200px;
          height: 30px;
          bottom: -2px;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }
  }

  .share-container {
    position: absolute;
    right: 16px;
    bottom: -20px;
    transform: translateY(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 78px;
    height: 78px;
    border: 1px solid #000000;

    @media (max-width: 767px) {
      position: relative;
      bottom: unset;
      right: unset;
      transform: unset;
      width: 48px;
      height: 48px;
      display: none;
      svg {
        width: 20px;
      }
    }
  }

  .image-inner {
    position: relative;
    //cursor: pointer;
    overflow: hidden;
    margin-top: 55px;

    &:hover {
      .image {
        //transform: scale(1.1);
      }
    }

    .image {
      transition: all .45s cubic-bezier(.23, -0.01, .03, 1);
    }

    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: all .45s cubic-bezier(.23, -0.01, .03, 1);
    }

  }

  .content :deep {
    max-width: 900px;
    margin: 64px auto 0;
    @media only screen and (max-width: 767px) {
      padding: 0 16px;
    }

    h2, h1, h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
    }

    span, p {
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 31px;

    }
  }

}

</style>
